import React from 'react';
import clsx from 'clsx'
import {Button} from '@material-ui/core'
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg'
import useStyles from '../styles/notificationMessageStyles'

export default function NotificationMessage({
  data,
  sendMessage,
  config,
  webSocket,
  notificationVisible,
  setNotificationVisible
}) {

  const { title: bannerTitle, text, button } = data
  const { title, url, payload } = button || {}
  const classes = useStyles(config);

  const handleBtnClick = () => {
    if(url) {
      if(url.includes('tel:'))
          window.location = url
      else
        window.open(url, '_blank');
    } else {
      sendMessage({ text: title, payload})
      setNotificationVisible(false)
    }
  }

  return (
    <div className={clsx(classes.root, !notificationVisible && 'hidden')} data-testid='notification-message'>
      <CloseIcon className='closeIcon'  onClick={() => setNotificationVisible(false)}/>
      {
        bannerTitle &&
        <div className='header'>{bannerTitle}</div>
      }
      <p><div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: text }} /></p>
      {
        button &&
        <Button className={classes.button} onClick={handleBtnClick} data-testid='notification-cta-btn'>{title}</Button>
      }
    </div>
  );
}

// const bcPayload = {
//   id: 912380912312,
//     type: "notification",
//     "data": {
//         text: "👋 👋 You can now access fund brochures and commentaries of selected Schroders funds. Try typing 'Schroders fund brochures' or 'Schroders fund commentaries' to learn more!",
//         button: {
//           title: "Learn More",
//           payload: "FL_90j1h23n1ji23no12m3k2m"
//         }
//       }
// }
