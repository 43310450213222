import React from 'react';
import { Slide } from '@material-ui/core';
import { v4 as uuid } from 'uuid'
import fieldTemplates from './fieldTemplates'
import TouForm from './templates/tou'
import LoginForm from './templates/login'
import useStyles from './styles'
import {useConfigContext} from '../../_context/config.context'

export default function Form({ open, sendForm, form }) {
  const {config} = useConfigContext()
  const classes = useStyles(config)
  const formName = form?.data?.formName ?? 'N.A'

  const formAction = ({ action, fields }) => {
    const formPayload = {
        type: 'form',
        id: uuid(),
        createdAt: new Date(),
        createdBy: 'user',
        data: {
            action,
            text: `${action.charAt(0).toUpperCase() + action.slice(1)} Form`,
            formName: formName,
            formFields: fields ? fields.textFields : []
        }
    }
    sendForm(formPayload)
  }

  const formSwitcher = () => {
    switch(formName) {
      case 'TOU_FORM':
      return <TouForm formAction={formAction} form={form}/>
      case 'USERNAME_PASSWORD_FORM':
      case 'USERNAME_PASSWORD_RETRY_FORM':
      case 'EMAIL_AUTH_FORM':
      case 'OTP_FORM':
      return <LoginForm formAction={formAction} form={form} data={fieldTemplates(formName)} />
      default:
      return (
        <div data-testid='default-form'/>
      )
    }
  }

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={classes.backdropContent}>
        {formSwitcher()}
      </div>
    </Slide>
  );
}
