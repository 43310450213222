import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  mainWrapper: {
    display: 'flex',
    boxSizing: 'border-box',
    width: ({WIDE_MODE}) => WIDE_MODE ? '100%' : 400,
    [theme.breakpoints.down('sm')]: {
      width: '100vw !important'
    }
  },
  scrollArrows: {
    padding: '18px 10px',
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'auto',
      fill: '#b3aeae'
    },
    '&.hide': {
      display: 'none'
    },
  },
  qrContainer: {
    padding: ({QR_SHOW_ALL}) => QR_SHOW_ALL ? '5px 8px' : '5px 2px',
    width: '100%',
    display: 'flex',
    maxWidth: ({WIDE_MODE}) => WIDE_MODE ? 'auto' : 400,
    overflowX: ({QR_SHOW_ALL}) => QR_SHOW_ALL ? 'auto' : 'scroll',
    flexWrap: ({QR_SHOW_ALL}) => QR_SHOW_ALL ? 'wrap' : 'no-wrap',
    justifyContent: ({QR_SHOW_ALL}) => QR_SHOW_ALL ? 'center' : 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw !important'
    },
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': { /* WebKit */
      width: 0,
      height: 0,
    }
  },
  qrOption: {
    padding: '6px 10px',
    lineHeight: 1,
    cursor: 'pointer',
    margin: 5,
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
    borderRadius: 15,
    fontWeight: 500,
    fontSize: 15,
    // boxShadow: '0px 0px 6px 1px #d2d2d2',
    transition: '0.2s ease-in-out',
    border: ({GLOBAL_COLOR}) => `1px solid ${GLOBAL_COLOR}`,
    color: ({GLOBAL_COLOR}) => GLOBAL_COLOR,
    '&.first': {
      marginLeft: ({QR_SHOW_ALL}) => QR_SHOW_ALL ? 5 : 'auto'
    },
    '&.last': {
      marginRight: ({QR_SHOW_ALL}) => QR_SHOW_ALL ? 5 : 'auto'
    },
    '&:active': {
      transform: 'translateY(4px)'
    },
    '&:hover': {
      border: ({BUTTON}) => `1px solid ${BUTTON.hoverState.borderColor}`,
      backgroundColor: ({BUTTON}) => BUTTON.hoverState.backgroundColor !== '' ? BUTTON.hoverState.backgroundColor : '#d9d9d9' ,
      color: ({BUTTON}) => BUTTON.hoverState.color,
      transform: 'scale(1.1)'
    }
  }
}));
