import { makeStyles } from '@material-ui/core/styles';

const widgetWidth = 400

export default makeStyles(theme => ({
  chatAreaBackground: {
    [theme.breakpoints.up('md')]: {
      padding: ({WIDE_MODE}) => WIDE_MODE ? '20px 0px 20px 20px' : 'auto',
      backgroundColor: ({WIDE_MODE, BACKGROUND_COLOR}) => WIDE_MODE ? BACKGROUND_COLOR : 'white',
    }
  },
  chatArea: {
    zIndex: 1999,
    position: ({WIDE_MODE}) => WIDE_MODE ? 'auto' : 'fixed',
    bottom: ({WIDE_MODE, launchButtonSize}) => WIDE_MODE ? 'auto' : launchButtonSize + 25,
    height: 'auto',
    right: ({WIDE_MODE, WIDGET_LOCATION}) => WIDE_MODE || WIDGET_LOCATION  === 'left' ? 'auto' :  15,
    left: ({WIDE_MODE, WIDGET_LOCATION}) => WIDE_MODE || WIDGET_LOCATION  !== 'left' ? 'auto' :  15,
    width: ({WIDE_MODE}) => WIDE_MODE ? '100%' : widgetWidth,
    transition: '.4s transform, .3s opacity',
    backgroundColor: 'white',
    boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.29)',
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? '28px 0 0 28px' : 10,
    transform: 'scale(0)',
    opacity: 0,
    transformOrigin: ({WIDGET_LOCATION}) => WIDGET_LOCATION  !== 'left' ? 'bottom right' : 'bottom left',
    overflow: 'hidden',
    '&.display': {
      transform: 'scale(1)',
      opacity: 1
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw) !important',
      right: '0px !important',
      bottom: '0px !important',
      borderRadius: '10px !important'
    }
  },
  copyrightLabel: {
    color: 'white',
    fontSize: 11,
    position: 'absolute',
    right: 10,
    bottom: 2,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
    '& a': {
      color: 'white'
    }
  },
  '@keyframes flickerAnimation': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}));
