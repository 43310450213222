import React, {useState} from 'react';
import {Helmet} from "react-helmet";

import Launcher from '../components/Launcher'
import ConfigErrorPage from './pages/configurationError'
import MaintenancePage from './pages/maintenance'
import UserContext from '../_context/user.context'
import ConfigContext from '../_context/config.context'
import settings from '../config/settings'
import defaultSettings from '../config.sample/settings'
import { connect } from 'react-redux'

import './App.css';

function App({user, dispatch, 
  chatbotReferral, 
  chatOpenByDefault,
  autoRestartSession,
  enableFeedback,
  hideRestartOnFeedback,
  enableFileUpload,
  showWatermark,
  useCustomLauncher,
  customLauncherHeight,
  customLauncherWidth,
  persistenMenuButton
}) {
  const requiredKeys = Object.keys(defaultSettings)
  const localSettings = Object.keys(settings)
  const missingKeys = requiredKeys.filter(x => !localSettings.includes(x))

  const [config, setConfig] = useState({...settings, 
    ...chatbotReferral && {CHATBOT_REFERRAL: chatbotReferral},
    ...chatOpenByDefault && {CHAT_OPEN_BY_DEFAULT: chatOpenByDefault==='true'},
    ...autoRestartSession && {AUTO_RESTART_SESSION: autoRestartSession==='true'},
    ...enableFeedback && {ENABLE_FEEDBACK: enableFeedback==='true'},
    ...hideRestartOnFeedback && {HIDE_RESTART_ALERT_ON_FEEDBACK_PAGE: hideRestartOnFeedback==='true'},
    ...enableFileUpload && {ENABLE_FILE_UPLOAD: enableFileUpload==='true'},
    ...showWatermark && {SHOW_WATERMARK: showWatermark==='true'},
    ...useCustomLauncher && {USE_CUSTOM_LAUNCHER: useCustomLauncher==='true'},
    ...customLauncherHeight && {CUSTOM_LAUNCHER_HEIGHT: parseInt(customLauncherHeight)},
    ...customLauncherWidth && {CUSTOM_LAUNCHER_WIDTH: parseInt(customLauncherWidth)},
    ...persistenMenuButton && {PERSISTENT_MENU_BUTTON: persistenMenuButton==='true'},
  })

  const modifyConfig = newConfig => {
    const configClone = JSON.parse(JSON.stringify(config))
    const updatedConfig = {...configClone, ...newConfig}
    setConfig(newConfig ? updatedConfig : settings)
  }

  if(missingKeys.length > 0) {
    return <ConfigErrorPage config={config} missingKeys={missingKeys} />
  } else if(settings.maintenance.active) {
    return <MaintenancePage config={config} />
  } else {
    return (
      <UserContext.Provider value={{user, dispatch}}>
        <ConfigContext.Provider value={{config, modifyConfig}}>
          {
            config.WIDE_MODE && <Helmet>
              <meta
                name="description"
                content={config.BOT_NAME}
              />
              <title>{config.BOT_NAME}</title>
            </Helmet>
          }
          <div className="App" style={{ fontFamily: config.FONT_FAMILY}}>
            <Launcher />
          </div>
        </ConfigContext.Provider>
      </UserContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(App)
