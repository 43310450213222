import { makeStyles } from '@material-ui/core/styles';

const backdropIndex = 1999

export default makeStyles(theme => ({
  backdrop: {
    zIndex: backdropIndex,
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? '28px 0 0 28px' : 8,
    position: 'absolute',
    '& label': {
      color: 'white',
      fontWeight: 700
    },
  },
  backdropContent: {
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: 0,
    left: ({isIE11}) => isIE11 ? '0' : 'auto',
    height: 'auto',
    width: ({WIDE_MODE}) => WIDE_MODE ? '100%' : 400,
    backgroundColor: 'white',
    borderRadius: ({WIDE_MODE}) => WIDE_MODE ? '28px 28px 0 28px' : 8,
    zIndex: backdropIndex + 1,
    '& .menuHeader': {
      padding: '15px 20px 0px 20px',
      fontSize: 18,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    }
  },
  clickToCloseLabel: {
    color: 'white',
    fontSize: 16,
    fontWeight: 700
  },
  container: {
    '& .persistentMenuArrow': {
      cursor: 'pointer',
      borderRadius: '50%',
      padding: '10px 16px',
      margin: '26px 10px',
      backgroundColor: '#4440408a',
      zIndex: 99,
      color: 'white',
      transition: '0.1s ease-in-out',
      '&:hover': {
        transform: 'scale(1.1)'
      },
      '&.hide': {
        display: 'none'
      }
    },
    '& .leftArrow': {
    position: 'absolute',
      left: 0,
      bottom: 30
    },
    '& .rightArrow': {
    position: 'absolute',
      right: 0,
      bottom: 30
    },
  },
  scrollContainer: {
    lineHeight: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    margin: '15px 20px 12px 20px',
    padding: '10px 0px',
    height: 100,
    'scrollbar-width': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': { /* WebKit */
      width: 0,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent'
    }
  },
  scrollItem: {
    boxSizing: 'border-box',
    width: 90,
    height: 90,
    margin: '0 10px',
    flex: '0 0 auto',
    borderRadius: 8,
    boxShadow: '0px 0px 6px 2px rgb(222, 222, 222)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    padding: '5px 5px',
    color: ({PERSISTENT_MENU}) => PERSISTENT_MENU?.color !== '' ? PERSISTENT_MENU.color : 'white',
    cursor: 'pointer',
    transition: '0.2s ease-in-out',
    backgroundColor: ({PERSISTENT_MENU, GLOBAL_COLOR}) => PERSISTENT_MENU?.backgroundColor !== '' ? PERSISTENT_MENU.backgroundColor : GLOBAL_COLOR,
    '& div': {
      textAlign: '-webkit-center'
    },
    '& .fas,.far,.fab,svg': {
      fontSize: 28,
      padding: '10px 12px 7px 12px',
      display: 'block'
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
    '&:active': {
      transform: 'translateY(2px)'
    }
  }
}));
