const settings = {
  BOT_TEXT_MAX_LINES: 25,
  TRAILING_MESSAGES_DISPLAYED: 100,
  maintenance: {
    active: false,
    title: "We're undergoing scheduled maintenance",
    message: 'Please check back soon, just putting the finishing touches on some pretty awesome updates!'
  },
  GA: [
    {
      type: 'event',
      name: 'testing event',
      payload: {
        event_category: 'sample',
        event_label: 'SAMPLE LABEL',
        value: 12
      } //payload depends on type of event being sent.
    }
  ],
  AUTO_RESTART_SESSION: false,
  ENABLE_FEEDBACK: false,
  HIDE_RESTART_ALERT_ON_FEEDBACK_PAGE: false,
  FEEDBACK_FORM_NAME: '',
  IMG_TEMPLATE_SHOW_FULL: false,
  PERSISTENT_MENU_BUTTON: true,
  WELCOME_SCREEN_SETTINGS: {
    display: false,
    welcomeTitle: 'Hello!',
    welcomeMessageMain: 'I am Pand.ai and I make retrieving financial information easy.',
    welcomeMessageSecondary: 'Ask me about anything.',
    actionButton: {
      active: false,
      text: 'Button Label',
      secondaryText: '',
      payload: 'FL5be7a85b4e2da6b76d7f3b64_0'
    }
  },
  WIDE_MODE: false,
  WIDGET_LOCATION: 'right',
  CHAT_OPEN_BY_DEFAULT: false,
  QR_LIMIT: 4,
  QR_SHOW_ALL: true,
  SHOW_USER_FORM_RESPONSE: false,
  SHOW_WATERMARK: false,
  SOCKET_DIALOG: {
    title: 'Session has been disconnected.',
    subTitle: 'Click the button below or refresh the page to restart the session',
    buttonText: 'Restart'
  },
  WEBSHARE_IGNORED_QRS: ['5ece45e8b3c31a04fdf4d36e'], //default webshare payload, to hide when webshare is unavailable
  USE_DYNAMIC_QR_LENGTH: false,
  USE_CUSTOM_LAUNCHER_ICON: false,
  USE_CUSTOM_LAUNCHER: true,
  MINIMIZE_LAUNCHER: false,
  CUSTOM_LAUNCHER_HEIGHT: 100,
  CUSTOM_LAUNCHER_WIDTH: 100,
  LAUNCHER_BUTTON_ID: 'launcherButton',
  USE_SESSION: false,
  ENABLE_FILE_UPLOAD: true,
  HEADER_MENU: true,
  HEADER_MENU_FOLDED: false,
  HEADER_MENU_ITEMS: [
    { label: "Minimise", icon: "fas fa-minus", payload: "minimise" }
  ],
  NAVIGATION_ITEMS: [
    { label: "Menu", icon: "fas fa-list-alt", payload: "FL611621abd4752744bdd8c563_0" },
    { label: "New Claim", icon: "fas fa-plus-circle", payload: "FL62fbedc196260c4438f283da_0"},
    { label: "Claim Status", icon: "fas fa-check-circle", payload: "FL62f340b675527a82a2f4e383_0"},
    { label: "Workshop List", icon: "fas fa-wrench", payload: "FL62fbf1fd96260c4438f283e8_0"},
    { label: "FAQ", icon: "fas fa-question-circle", payload: "FL612371bddfe5e7150c658141_0"}
  ],

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  //                                           Styling Options                                            //
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  /*
    Available installed fonts:
    - 'Helvetica Neue'
    - 'Raleway, sans-serif'
    - 'Noto Sans, sans-serif'
    - 'Inter, sans-serif'
  */

  BACKGROUND_COLOR: "#003781",
  BUTTON: {   //non hover state button colors follow global color, this is to prevent color clashing
    hoverState: {
      color: '',
      borderColor: '',
      backgroundColor: ''
    }
  },
  BOT_NAME: "Allianz Insurance Singapore",
  BOT_MSG: { //change values to string if u want to change the colors, otherwise leave as null
    color: null,
    backgroundColor: "#dfeff2",
    timeStampColor: '#13a0d3'
  },
  FONT_FAMILY: 'AllianzNeo, sans-serif',
  GLOBAL_COLOR: "#003781",
  HEADER_COLOR: "#003781",
  NOTIFICATION_BANNER: {
    color: "#000000",
    backgroundColor: '#003781',
    button: {
      color: 'white',
      backgroundColor: '#003781'
    }
  },
  PERSISTENT_MENU: {
    color: '',
    backgroundColor: ''
  },
  SEND_ICON_COLOR: "#003781",
  SIDEBAR: {
    enableEndChatButton: false,
    color: "white",
    borderColor: "white",
    backgroundColor: "transparent",
    logo: {
      width: 86,  // leave as auto unless width is broken. Either a number, 'auto' or '90%' (percentage based value)
      height: 106, // leave as auto unless height is broken. Either a number, 'auto' or '90%' (percentage based value)
      url: 'https://www.pand.ai'
    },
  },
  SHARE_BUTTON_BACKGROUND_COLOR: "#2d55b2cf",
  SHARE_ICON_COLOR: "white",
  USER_MESSAGE_COLOR: "white",
  WELCOME_COLOR: "#003781"
}

export default settings