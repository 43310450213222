import { makeStyles } from '@material-ui/core/styles';

const notificationZIndex = 1900 //to ensure that it is hidden beneath forms

export default makeStyles(theme => ({
  root: {
    color: 'white',
    display: 'block',
    position: 'relative',
    marginTop: ({WIDE_MODE}) => WIDE_MODE ? 0 : 10,
    marginBottom: 10,
    marginRight: ({WIDE_MODE}) => WIDE_MODE ? 25 : 12,
    borderRadius: 10,
    padding: '12px 30px 12px 20px',
    backgroundColor:({NOTIFICATION_BANNER: { backgroundColor }}) => backgroundColor,
    boxShadow: '0px 1px 3px 1px #00000030',
    marginLeft: ({WIDE_MODE}) => WIDE_MODE ? 0 : -10,
    '& .header': {
      margin: '7px 0 0 0',
      fontWeight: 'bold'
    },
    '& p': {
      margin: '10px 0',
      fontSize: 14,
      fontWeight: 500,
      '& a:link': {
        color: '#FFF3C6'
      },
      '& a:visited': {
        color: '#FFE4C6'
      },
    },
    '& .closeIcon': {
      position: 'absolute',
      top: 7,
      right: 7,
      width: 20,
      padding: 5,
      borderRadius: 40,
      transition: '0.2s ease-in-out',
      fill: 'white',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#c1baba80'
      }
    },
    '&.hidden': {
      display: 'none'
    },
  },
  button: {
    textTransform: 'none',
    fontFamily: ({FONT_FAMILY}) => FONT_FAMILY,
    backgroundColor: 'white',
    color: 'black',
    borderRadius: 20,
    padding: '2px 16px',
    fontWeight: 'bold',
    fontSize: 12,
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}));
